import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState } from "react";

interface Props {
  selectedRows: any;
  setSelectedRows: any;
  columns: GridColDef[];
  rows: any;
  rowsLength: number;
}

function FilterModal({ open, onClose, onApplyFilters, columns }: any) {
  const [filters, setFilters] = useState([{ column: "", value: "" }]);

  const handleAddFilter = () => {
    setFilters([...filters, { column: "", value: "" }]);
  };

  const handleApply = () => {
    onApplyFilters(filters);
    onClose();
  };

  type FilterField = "column" | "value";

  const handleChange = (index: number, field: FilterField, value: string) => {
    const newFilters = [...filters];
    newFilters[index][field] = value;
    setFilters(newFilters);
  };

  const clearFilters = () => {
    setFilters([{ column: "", value: "" }]);
    handleApply();
  };

  const removeFilter = (index: number) => {
    const remainingFilters = filters.filter((_, id) => id !== index);
    setFilters(remainingFilters);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          position: "absolute",
          padding: "36px",
          backgroundColor: "white",
          width: "75%",
          borderRadius: "12px",
        }}
      >
        {filters.length > 0 ? (
          filters.map((filter, index) => (
            <div
              key={index}
              style={{
                marginBottom: "10px",
                display: "flex",
                gap: "12px",
                alignItems: "center",
              }}
            >
              <TextField
                select
                value={filter.column}
                onChange={(e) => handleChange(index, "column", e.target.value)}
                SelectProps={{
                  native: true,
                }}
                sx={{ width: "50%" }}
              >
                <option value="">Select a column</option>
                {columns.map((column: any) => (
                  <option key={column.field} value={column.field}>
                    {column.headerName}
                  </option>
                ))}
              </TextField>
              <TextField
                sx={{ width: "50%" }}
                label="Value"
                value={filter.value}
                onChange={(e) => handleChange(index, "value", e.target.value)}
              />
              <IconButton onClick={() => removeFilter(index)}>
                <CloseIcon />
              </IconButton>
            </div>
          ))
        ) : (
          <h3>Please Add Filters</h3>
        )}
        <div style={{ display: "flex", gap: "16px", marginTop: "16px" }}>
          <Button variant="contained" color="primary" onClick={handleAddFilter}>
            Add More Columns
          </Button>
          <Button variant="contained" color="primary" onClick={handleApply}>
            Apply Filter
          </Button>
          {filters.length > 0 ? (
            <Button variant="outlined" color="primary" onClick={clearFilters}>
              Remove Filter
            </Button>
          ) : null}
        </div>
      </div>
    </Modal>
  );
}

export default function ChangeStageTable({
  selectedRows,
  setSelectedRows,
  rows,
  columns,
  rowsLength,
}: Props) {
  const [originalRows, setOriginalRows] = useState(rows);
  const [filteredRows, setFilteredRows] = useState(rows);
  const [modalOpen, setModalOpen] = useState(false);

  const applyFilters = (filters: any) => {
    let newFilteredRows = [...originalRows];
    filters.forEach((filter: any) => {
      if (filter.column && filter.value) {
        newFilteredRows = newFilteredRows.filter((row) =>
          row[filter.column]
            .toString()
            .toLowerCase()
            .includes(filter.value.toString().toLowerCase())
        );
      }
    });
    setFilteredRows(newFilteredRows);
  };

  useEffect(() => {
    if (rows) {
      setFilteredRows(rows);
      setOriginalRows(rows);
    }
  }, [rows]);

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setModalOpen(true)}
        sx={{ marginTop: "1rem" }}
      >
        Add Filters
      </Button>
      <FilterModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onApplyFilters={applyFilters}
        columns={columns}
      />
      <DataGrid
        rows={filteredRows}
        columns={columns}
        autoHeight
        components={{
          Toolbar: GridToolbar,
        }}
        isCellEditable={(item) => item.id === 0}
        initialState={{
          pagination: { pageSize: 5000 },
        }}
        style={{ marginTop: 10 }}
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRowData = rows.filter((row: any) =>
            selectedIDs.has(row.id)
          );
          setSelectedRows(selectedRowData);
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        checkboxSelection
      />
    </div>
  );
}
